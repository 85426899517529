import React from "react"
import styled from "styled-components"

const Wrapper = styled.ul`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 2rem;
`

const AnswerButtonContainer = ({ children }) => {
  return (
    <Wrapper>
      {children}
    </Wrapper>
  )
}

export default AnswerButtonContainer
