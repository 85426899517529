import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const buttonStyle = `
  width: 100%;
  padding: 1rem 0;
  border: 0;
  border-radius: 0.5rem;
  background: white;
  font-size: 1.25rem;
  line-height: 1.2;
`

const Button = styled.button`
  ${buttonStyle}
  border: 0;

  ${props => props.hasBgImage && `
    position: relative;
    padding: 33% 0;
    color: rgba(255, 255, 255, 0.8);
    background-repeat: no-repeat;
    background-size: contain;
    background-color: black;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.7);
    }

    span {
      position: relative;
      z-index: 2;
    }
  `}
`

const StyledLink = styled(Link)`
  ${buttonStyle}
  display: inline-block;
  text-decoration: none;
  color: #363636;
  text-align: center;
`

const AnswerButton = ({ label, onClick, image, to }) => {
  return (
    to ?
      <StyledLink to={to}>
        {label}
      </StyledLink>
    :
      <Button
        onClick={onClick}
        style={{ backgroundImage: image ? `url(${encodeURI(image)})` : "none" }}
        hasBgImage={!!image}
      >
        <span>{label}</span>
      </Button>
  )
}

export default AnswerButton
