import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import Header from "../HeaderSub"
import Screen from "./Screen"
import ChoosePatient from "./ChoosePatient"

import hvLogo from "../../images/hildingvilding_spion.svg"

const Wrapper = styled.div`
  max-width: 40rem;
  margin: 0 auto;
  padding: 3rem 1rem 1rem;
`

const TheOperationGame = ({ lang, title, setBgColor, screens }) => {
  const intl = useIntl()
  const [currentScreenNumber, setCurrentScreenNumber] = useState(0)
  const [patient, setPatient] = useState(null)
  const currentScreen = currentScreenNumber ? screens.find((x) => x.number === currentScreenNumber) : null
  const isLastScreen = currentScreenNumber === screens.length

  setBgColor(currentScreen && currentScreen.bgColor || null)

  useEffect(() => {
    window.scroll(0, 0)
  }, [currentScreenNumber])

  return (
    <Wrapper>
      <Header
        lang={lang}
        title={title}
        room={6}
        roomSymbol={hvLogo}
      />
      {currentScreen ?
        <Screen
          text={currentScreen.text}
          textColor={currentScreen.textColor}
          image={currentScreen[`image_${patient.name.toLowerCase()}`] || currentScreen.image_tim}
          imageAltText={currentScreen.imageAltText}
          buttons={currentScreen.buttons}
          onClick={(number) => setCurrentScreenNumber(number)}
          patient={patient}
          isLast={isLastScreen}
          lang={lang}
        />
        :
        <ChoosePatient
          onClick={(number) => setCurrentScreenNumber(number)}
          onChoosePatient={(p) => setPatient(p)}
        />
      }
    </Wrapper>
  )
}

export default TheOperationGame
