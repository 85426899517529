import React, { useState } from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"

import NwHelmet from "../components/NwHelmet"
import TheOperationGame from "../components/the-operation-game"
import BrowserUpdateMessage from "../components/BrowserUpdateMessage"
import CookieBanner from "../components/CookieBanner"

const Wrapper = styled.div`
  min-height: 100%;
  background: ${props => props.bgColor || "#D1EFF7"};
`

export default function TheOperationGameTemplate({
  data: { allTheOperationGameYaml },
  pageContext: { lang, pagePath },
}) {
  const intl = useIntl()
  const title = intl.formatMessage({ id: "theOperationGame" })
  const [bgColor, setBgColor] = useState(null)

  return (
    <Wrapper bgColor={bgColor}>
      <NwHelmet
        lang={lang}
        path={pagePath}
        title={title}
        image={{
          url: `/assets/share-images/NW_Delning8_HV_${lang === "sv" ? "Sve" : "Eng"}.jpg`,
          width: 1200,
          height: 630,
        }}
      />
      <TheOperationGame
        lang={lang}
        title={title}
        screens={allTheOperationGameYaml.nodes}
        setBgColor={setBgColor}
      />
      <BrowserUpdateMessage />
      <CookieBanner lang={lang} />
    </Wrapper>
  )
}

export const pageQuery = graphql`
  query($lang: String) {
    allTheOperationGameYaml(filter: { lang: { eq: $lang } }) {
      nodes {
        number
        text
        image_tim
        image_jasmin
        image_cleo
        image_tore
        imageAltText
        bgColor
        textColor
        buttons {
          label
          goToScreen
          image_tim
          image_jasmin
          image_cleo
          image_tore
        }
      }
    }
  }
`
