import React from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import AnswerButtonContainer from "./AnswerButtonContainer"
import AnswerButton from "./AnswerButton"

import getRoomLink from "../../utils/get-room-link"

const Wrapper = styled.div`
  p {
    font-size: 1rem;
    line-height: 1.5;
    color: ${props => props.textColor || "#363636"};
  }

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    width: calc(50% - 1rem);
    margin: 0 0.5rem 1rem;
  }

  ${props => (props.numberOfButtons === 3 || props.numberOfButtons === 1) && `
    li {
      width: 80%;
    }
  `}

  ${props => props.numberOfButtons > 2 && `
    @media (max-width: 640px) {
      li {
        width: 80%;
      }
    }
  `}
`

const Screen = ({ patient, text, textColor, image, imageAltText, buttons, onClick, isLast, lang }) => {
  const intl = useIntl()

  const getPronoun = (pronoun) => {
    const isMale = patient.sex === "male"

    switch (pronoun) {
      case "{hen}":
        return isMale ? "han" : "hon"
      case "{Hen}":
        return isMale ? "Han" : "Hon"
      case "{hens}":
        return isMale ? "hans" : "hennes"
      case "{henom}":
        return isMale ? "honom" : "henne"
      case "{they}":
        return isMale ? "he" : "she"
      case "{They}":
        return isMale ? "He" : "She"
      case "{their}":
        return isMale ? "his" : "her"
      case "{Their}":
        return isMale ? "His" : "Her"
      case "{them}":
        return isMale ? "him" : "her"
    }
  }

  const transformString = (string) =>
    string
      .replace(/{patient}/g, patient.name)
      .replace(/{hen}/g, getPronoun("{hen}"))
      .replace(/{Hen}/g, getPronoun("{Hen}"))
      .replace(/{hens}/g, getPronoun("{hens}"))
      .replace(/{henom}/g, getPronoun("{henom}"))
      .replace(/{they}/g, getPronoun("{they}"))
      .replace(/{They}/g, getPronoun("{They}"))
      .replace(/{their}/g, getPronoun("{their}"))
      .replace(/{Their}/g, getPronoun("{Their}"))
      .replace(/{them}/g, getPronoun("{them}"))

  return (
    <Wrapper textColor={textColor} numberOfButtons={(buttons || []).length}>
      {image && <img src={image} alt={transformString(imageAltText)} />}
      <p>{transformString(text)}</p>
      <AnswerButtonContainer>
        {buttons && buttons.map((button) => (
          <li key={button.label}>
            <AnswerButton
              label={transformString(button.label)}
              onClick={() => onClick(button.goToScreen)}
              image={button[`image_${patient.name.toLowerCase()}`] || button.image_tim}
            />
          </li>
        ))}
        {isLast &&
          <>
            <li>
              <AnswerButton
                label={intl.formatMessage({ id: "startAgain" })}
                onClick={() => onClick(0)}
              />
            </li>
            <li>
              <AnswerButton
                label={intl.formatMessage({ id: "finish" })}
                to={getRoomLink(6, lang)}
              />
            </li>
          </>
        }
      </AnswerButtonContainer>
    </Wrapper>
  )
}

export default Screen
