import React from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import tim from "../../images/get-anaesthesia-game/tim.svg"
import jasmin from "../../images/get-anaesthesia-game/jasmin.svg"
import cleo from "../../images/get-anaesthesia-game/cleo.svg"
import tore from "../../images/get-anaesthesia-game/tore.svg"

const Wrapper = styled.div`
  h1 {
    margin: 0 0 2rem;
    text-align: center;
  }

  ul {
    list-style: none;
    padding: 0;
  }

   li {
     width: 50%;
     float: left;
   }

   @media (min-width: 640px) {
     li {
       width: 25%;
     }
   }
`

const Button = styled.button`
  display: block;
  width: calc(100% - 1rem);
  max-width: 8rem;
  margin: 0 auto 1.5rem;
  border: 0;
  background: none;
  padding: 0.5rem 0;

   p {
     margin: 0;
     font-size: 1.2rem;
   }
`

const ChoosePatient = ({ onClick, onChoosePatient }) => {
  const intl = useIntl()

  const handleClick = (patient) => {
    onClick(1)
    onChoosePatient(patient)
  }

  return (
    <Wrapper>
      <h1>{intl.formatMessage({ id: "selectChild" })}</h1>
      <ul>
        <li>
          <Button onClick={() => handleClick({ name: "Tim", sex: "male" })}>
            <img src={tim} role="presentation" />
            <p>Tim</p>
          </Button>
        </li>
        <li>
          <Button onClick={() => handleClick({ name: "Jasmin", sex: "female" })}>
            <img src={jasmin} role="presentation" />
            <p>Jasmin</p>
          </Button>
        </li>
        <li>
          <Button onClick={() => handleClick({ name: "Cleo", sex: "female" })}>
            <img src={cleo} role="presentation" />
            <p>Cleo</p>
          </Button>
        </li>
        <li>
          <Button onClick={() => handleClick({ name: "Tore", sex: "male" })}>
            <img src={tore} role="presentation" />
            <p>Tore</p>
          </Button>
        </li>
      </ul>
    </Wrapper>
  )
}

export default ChoosePatient
